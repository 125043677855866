<template>
  <div class="dashboard-board-list">
    <m-ratio-box class="ratio-box" :ratio="56" v-for="(item, index) in boardList" :key="index">
      <div class="board-item">
        <div class="board-item-inner">
          <div class="label">{{ item.label }}</div>
          <div class="value">
            <span class="money-text" v-if="isMoney(item.valueProp)">{{ moneyText }}</span>
            <!-- <template v-if="isMoney(item.valueProp)">{{ moneyText }}</template> -->
            <count-to
              :start-val="0"
              :decimals="item.decimals"
              :end-val="boardData[item.valueProp] || 0"
            />
            {{ isRota(item.valueProp) ? '%' : '' }}
          </div>
          <div class="ratio" :class="getRatioClass(item.ratio)">
            <RightTop v-if="boardData[item.ratio] >= 0" class="svg-icon"/>
            <RightBottom v-else class="svg-icon"/>
            {{ Math.abs(boardData[item.ratio]) || 0 }}%
          </div>
        </div>
      </div>
    </m-ratio-box>
  </div>
</template>

<script>
import { boardList } from './boardData'
import CountTo from 'vue-count-to'
import { mapState } from 'vuex'
import WalletSvg from './svg/wallet.svg?inline'
import { todayData } from '@/apiForManage/dashboard'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import RightTop from '@/assets/icons/rightTop.svg?inline'
import RightBottom from '@/assets/icons/rightBottom.svg?inline'
export default {
  components: { CountTo, WalletSvg, RightTop, RightBottom },
  mixins: [mixRateAndMoneyMethod],
  data () {
    return {
      boardList,
      boardData: {}
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId
    })
  },
  created () {
    this.getRealTimeData()
  },
  methods: {
    async getRealTimeData () {
      const { data = {} } = await todayData()
      this.boardData = data
    },
    getRatioClass (ratio) {
      if (this.boardData[ratio] >= 0) {
        return 'ratio-up'
      } else {
        return 'ratio-down'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.dashboard-board-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  .board-item {
    user-select: none;
    height: 100%;
    display: flex;
    position: relative;
    padding: calc(16em / 14), 0;
    border-radius: @mediumRadius;
    background-color: #fff;
    box-shadow: @modelShadow;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 2px 6px 0 rgba(21, 34, 50, 0.3);
    }
    .board-item-inner {
      margin: calc(-1em / 14) 0 0 0;
      flex: 1 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-left: calc(1em / 14);
      .value {
        display: flex;
        align-items: flex-end;
        font-size: calc(18em / 14);
        color: #5A607F;
        font-weight: 600;
        line-height: calc(25em / 14);
        .money-text{
          font-size: calc(11em / 14);
          line-height: calc(30em / 14);
        }
      }
      .label {
        font-size: calc(13em / 14);
        font-weight: 400;
        color: #5a607f;
        line-height: calc(18em / 14);
      }
      .ratio{
        display: flex;
        align-items: center;
        font-size: 14px;
        &.ratio-up{
          color: #FF8A8A;
        }
        &.ratio-down{
          color: #64c8bc;
        }
        .svg-icon{
          width: 16px;
          margin-right: calc(3em / 14);
        }
      }
    }
  }
  .ratio-box {
    width: calc(98.5% / 7 - 7.5em / 14);
  }
}
</style>
